/* Navbar container */
.navbar {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  height: 70px;
  background-color: rgb(0, 9, 63);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* Sidebar styling */


.navbar .logo {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.navbar .logo a {
  text-decoration: none;
}

/* .navbar .logo img {
  width: 300px;
  height: auto;
} */

/* Logo styling */
.navbar .logo a {
  color: #ECF0F1;
  font-size: 25px;
  text-decoration: none;
  font-weight: bold;
  margin-left: 20px;
}

/* Menu icon for small screens */
.menu-icon {
  display: none; /* Hide by default on larger screens */
  cursor: pointer;
  font-size: 24px;
  color: #ECF0F1;
  margin-right: 20px;
}

/* Navigation links in a row */
.nav-links {
  display: flex;
  align-items: center;
}

/* List items in the menu bar */
.nav-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-links .dropdown strong{
  align-items: center;
  color: red;
}

.nav-links center{
  color: #ECF0F1;
}
.nav-links li {
  margin-right: 20px;
  position: relative;
}

/* Links in the menu bar */
.nav-links a {
  color: #ECF0F1;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #3498DB;
}

/* Button styling */
.white_btn {
  border: none;
  outline: none;
  padding: 12px 20px;
  background-color: #3498DB;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #ECF0F1;
  transition: background-color 0.3s;
}

.white_btn:hover {
  background-color: #2980B9;
}

/* Dropdown styling */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(0, 9, 63);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Style the sub-links */
.dropdown-content .nav-links-sub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Hide the sub-links by default */
.dropdown-content .nav-links-sub {
  display: none;
}

/* Display the sub-links when hovering over the dropdown menu */
.dropdown-content:hover .nav-links-sub {
  display: block;
}

/* Style the sub-link items */
.nav-links-sub li {
  margin-right: 20px;
}


/* Responsive menu for small screens */
@media (max-width: 768px) {

  .navbar {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 110px;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: -260px; /* Hidden by default */
    width: 250px;
    height: 100%;
    background-color: rgb(0, 9, 63);
    transition: left 0.3s;
    z-index: 2;
    overflow-y: auto;
  }
  
  .sidebar.open {
    left: 0; /* Displayed when open */
    padding-top: 20px;
  }
  
  /* Sidebar items */
  .sidebar ul {
    list-style: none;
    padding: 20px;
    position: relative;
  }
  
  .sidebar li {
    margin-bottom: 10px;
  }
  
  .sidebar a {
    color: #ECF0F1;
    text-decoration: none;
    font-size: 16px;
  }
  .sidebar span{
    color: #ECF0F1;
  }
  
  /* Style sidebar links */
  .sidebar a:hover {
    color: #3498DB;
  }
  
  /* Close button styling */
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #ECF0F1;
    font-size: 24px;
    cursor: pointer;
  }
  .menu-icon {
    display: block; /* Show the menu icon on small screens */
  }
  .nav-links ul {
    position: relative;
    display: none; /* Hide the links by default */
    flex-direction: column;
    
  }
  .navbar .logo img {
    width: 80px; /* Adjust the width for smaller screens */
  }

  .nav-links.open ul {
    display: flex;
  }
 
}
/* Add these styles to hide sub-links by default */
.navbar .dropdown-content {
  display: none;
}

/* Display the sub-links when hovering over "ONGOING OPPORTUNITIES" */
.navbar .dropdown:hover .dropdown-content {
  display: block;
}

/* Style the sub-links */
.navbar .dropdown-content .nav-links-sub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Hide the sub-links when not hovering over "ONGOING OPPORTUNITIES" */
.navbar .dropdown-content .nav-links-sub {
  display: none;
}

/* Display the sub-links when hovering over the dropdown menu */
.navbar .dropdown-content:hover .nav-links-sub {
  display: block;
}

/* Style the sub-link items */
.navbar .nav-links-sub li {
  margin-right: 20px;
}
