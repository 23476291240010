/* Footer.css */

/* Base styles for the footer */
.footer {
    background-color: rgb(0, 9, 63);
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  /* Social links container */
  .social-links {
    margin-bottom: 10px;
  }
  
.fix{
  align-items: center;
}

  .social-links a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  
  /* Copyright text */
  .copyright {
    font-size: 14px;
  }
  
  /* Add a media query for responsiveness */
  @media (max-width: 768px) {
    /* For screens with a maximum width of 768px (typical for mobile devices) */
  
    /* Center-align the social links and reduce margin */
    .social-links {
      text-align: center;
      margin: 10px 0;
    }
  
    /* Reduce font size for the social links */
    .social-links a {
      font-size: 14px;
    }
  }
  