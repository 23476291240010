.internship_list {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	padding-top: 100px;
  }
  
  .internship {
	border: 2px solid #ddd;
	border-radius: 8px;
	padding: 20px;
	margin: 20px 0;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s;
  }
  
  .internship:hover {
	transform: translateY(-5px);
  }
  
  .internship h3 {
	font-size: 24px;
	margin: 0 0 10px;
	color: #333;
  }
  
  .internship p {
	font-size: 16px;
	margin: 0 0 20px;
	color: #555;
  }
  
  .internship a {
	display: inline-block;
	padding: 10px 20px;
	margin: 10px;
	background-color: #007BFF;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
	transition: background-color 0.3s;
  }
  
  .internship a:hover {
	background-color: #0056b3;
  }
  
  .internship img {
	max-width: 100%;
	height: auto;
	/* margin-left: 50%; */
	margin-top: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  